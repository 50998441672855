export default {
    zh:{
        buildPageCard:'',
        buildPage:{
            queryParam:{

            },
            columns:{

            },
            form:{

            }
        }
    },
    en:{
        buildPageCard:'',
        buildPage:{
            queryParam:{

            },
            columns:{

            },
            form:{

            }
        }
    }
}